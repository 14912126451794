import type { RouteLocationRaw } from "#vue-router";
import type { I18nMessage } from "~/core/i18n/i18n.model";

export type MainHeaderNavigationItem = {
  label: I18nMessage;
  to: RouteLocationRaw;
};

export function useMainHeaderNavigation() {
  const { t } = useI18n();
  const catalogEnabled = useFeatureFlag("catalog");

  const items = computed<MainHeaderNavigationItem[]>(() => {
    return [
      ...(catalogEnabled
        ? [
            {
              label: t("catalog.navigation_label"),
              to: "/catalog",
            },
          ]
        : [
            {
              label: t("search.page.navigation_label"),
              to: "/search",
            },
          ]),
      {
        label: t("enterprise.page.navigation_label"),
        to: "/enterprise",
      },
      {
        label: t("bespoke.page.navigation_label"),
        to: "/bespoke",
      },
      {
        label: t("methodology.page.navigation_label"),
        to: "/methodology",
      },
      {
        label: t("blog.page.navigation_label"),
        to: "/blog",
      },
    ];
  });

  return { items };
}
